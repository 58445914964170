<template>
  <div id="app" class="d-flex flex-column">
    <div id="page-content">
      <b-navbar fixed="top" class="flex-md-nowrap p-0">
        <b-navbar-brand class="col-sm-3 col-md-2 mr-0 pt-1 p-0">
          <b-nav>
            <b-nav-item to="/">
              <img
                v-if="$store.getters.showTBILogo"
                src="./assets/tbi_logo.png"
                alt="True Bearing Insights"
                height="36"
              >
              <img
                v-else
                src="./assets/bam_logo.svg"
                alt="Balyasny Asset Management"
                height="30"
              >
              <small v-if="$store.getters.showIfchorLogo">
              -
              </small>
              <a href="https://ifchor.com/" target="_blank">
                <img
                  style="margin-left: 6px;"
                  v-if="$store.getters.showIfchorLogo"
                  src="./assets/ifchor_logo.png"
                  alt="IFCHOR"
                  height="30"
                >
              </a>
            </b-nav-item>
          </b-nav>
        </b-navbar-brand>
        <Toolbar
          v-model="current_date"
          v-bind:connecting="$store.state.connecting"
        />
      </b-navbar>
      <div v-if="!$auth.loading">
        <b-container fluid v-if="$auth.isAuthenticated">
          <b-row>
            <b-nav class="col-md-2 d-none d-md-block sidebar">
              <Sidebar />
            </b-nav>
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
              <router-view v-bind:data="state"></router-view>
            </main>
          </b-row>
        </b-container>
      </div>
    </div>
    <footer id="sticky-footer" class="py-4">
      <div class="container text-center">
        <small>
          Copyright &copy; 2018-{{ (new Date()).getFullYear() }} True Bearing Insights LLC
          | Version {{ version }}
          <span v-if="portfolioInfo">
            | Run completed {{ portfolioInfo.runEndTime | moment("from") }}
            - Last checked {{ portfolioInfo.lastFetched | moment("from") }}
            <span v-if="$store.getters.isTBI">
              - <b-link v-bind:href="portfolioInfo.runURL">Open in MLflow</b-link>
            </span>
          </span>
        </small>
      </div>
    </footer>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Sidebar from '@/components/Sidebar.vue'

const version = process.env.VUE_APP_VERSION || 'development';

export default {
  props: ['state'],
  computed: {
    version() {
      return version.replace('tbi-dashboard-', '');
    },
    current_date: {
      get() {
        return this.$store.state.current_date;
      },
      set(value) {
        this.$store.dispatch('set_current_date', value);
      }
    },
    portfolioInfo() {
      console.log("portfolioInfo", this.$store.getters.currentMode)
      if (this.$store.getters.currentMode=="viterra-ais") {
      return this.$store.state.data.cata({
                    NotAsked: () => false,
                    Loading: () => false,
                    Error: () => false,
                    Success: () => ({
                      lastFetched: new Date(Number(0)),
                      runStartTime: new Date(Number(1)),
                      runEndTime: new Date(Number(1)),
                      runDuration: Number(1) - Number(0),
                      runURL: "d.run_url"
                    })
                  })        
        // return false
      }
      return this.$store.state.data.cata({
        NotAsked: () => false,
        Loading: () => false,
        Error: () => false,
        Success: d => ({
          lastFetched: new Date(Number(d.last_checked)),
          runStartTime: new Date(Number(d.run_start_time)),
          runEndTime: new Date(Number(d.run_end_time)),
          runDuration: Number(d.run_end_time) - Number(d.run_start_time),
          runURL: d.run_url
        })
      })
    }
  },
  components: {
    Toolbar,
    Sidebar
  }
}
</script>
